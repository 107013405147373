#graph {
  width: 100%;
  height: calc(100vh - 194px);
  position: relative;
}

#toolbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // background: rgb(142 142 142 / 17%);
  //padding: 15px;
}

#toolbox input {
  width: 30px;
  opacity: 0.4;
}

#toolbox input:hover {
  opacity: 1;
  cursor: pointer;
}

#hidden-file-upload {
  display: none;
}

#download-input {
  margin: 0 0.5em;
}

.conceptG text {
  fill: white;
  pointer-events: none;
}

.co2-diff {
  fill: black !important;
}

.co2-num {
  fill: rgb(0, 251, 29) !important;
}
.cp-num {
  fill: rgb(211, 248, 3) !important;
}
.w-num {
  fill: rgb(255, 51, 51) !important;
}
/*
.conceptG.selected text {
  fill: black;
}
.conceptG:hover text {
  fill: black;
}
*/

.accuracy-high {
  fill: rgb(0, 150, 17) !important;
}
.accuracy-medium {
  fill: rgb(166, 195, 0) !important;
}
.accuracy-low {
  fill: rgb(255, 51, 51) !important;
}

marker {
  fill: #333;
}

g.conceptG circle,
g.conceptG rect {
  stroke: #333;
  stroke-width: 2px;
  text-overflow: ellipsis;
}

.process-rect {
  fill: rgb(38, 129, 114);
  stroke: #333;
  stroke-width: 2px;
}
.resource-rect {
  fill: rgb(213, 62, 12);
  stroke: #333;
  stroke-width: 2px;
}
.facility-rect {
  fill: rgb(75, 80, 79);
  stroke: #333;
  stroke-width: 2px;
}
.asset-rect {
  fill: rgb(75, 80, 79);
  stroke: #333;
  stroke-width: 2px;
}
.co2-rect {
  fill: rgb(95, 95, 95);
  stroke: #333;
  stroke-width: 2px;
}

.end-co2-rect {
  fill: #644900;
  stroke: #333;
  stroke-width: 2px;
}

g.conceptG:hover circle,
g.conceptG:hover rect {
  fill: rgb(104, 104, 104);
  cursor: pointer;
}

g.selected circle,
g.selected rect {
  fill: rgb(31, 30, 30);
}

path.link {
  fill: none;
  stroke: #333;
  stroke-width: 2px;
  cursor: default;
  padding: 12px;
}

path.link:hover {
  stroke: rgb(94, 196, 204);
  stroke-width: 2px;
}

g.connect-node circle {
  fill: #beffff;
}

path.link.hidden {
  stroke-width: 0;
}

path.link.selected {
  stroke: rgb(229, 172, 247);
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background: rgb(0 0 0 / 80%);
  color: white;
  border-radius: 5px;
  width: 130px;
  padding: 5px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  font-size: 13px;
  display: block;
  height: 25px;
  line-height: normal;
  text-align: center;
}

.lg-gray {
  // background:#f1f1f1;
  // border: 1px solid #dcdcdc;
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  background: #fff;
}

#toolbox button {
  background: #00000070;
  color: #fff;
  border: unset;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
}

button#resetzoom {
  border-radius: 5px;
}

button#download-input {
  background: #82d616; /* Set the background color */
  color: #fff; /* Set the text color */
  border: none; /* Remove border */
  padding: 8px 20px; /* Padding around the text */
  font-size: 14px; /* Size of the text */
  font-weight: 500; /* Font weight */
  margin-right: 10px; /* Space on the right side */
  cursor: pointer; /* Change cursor on hover */
  position: absolute;
  top: 0;
  right: 0;
}

.lft {
  display: flex;
  align-items: center;
  width: 130px;
  height: 180px;
  justify-content: center;
  border: 2px solid rgb(170 170 170 / 79%);
}

button#reactangle {
  background: white;
  border: 2px dashed rgba(113, 113, 113, 0.7882352941);
  color: #717171;
  font-size: 13px;
  font-weight: 600;
  width: 85px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% + 40px);
  left: 45px;
  transform: translateY(-50%);
  text-overflow: ellipsis;
}

button#circle {
  background: white;
  border-radius: 50%;
  padding: 8px 20px;
  width: 78px;
  height: 78px;
  border: 2px dashed rgba(113, 113, 113, 0.7882352941);
  color: #717171;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 47.5px;
  top: calc(50% - 40px);
  transform: translateY(-50%);
  text-overflow: ellipsis;
}

// #resetzoom {
//   top: 186px;
//   left: 13px;
//   max-height: 32px;
// }

.controls {
  background: white !important;
  padding: 5px;
  margin-right: 40px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white; // change to match your design
  border-radius: 0px; // adjust as needed
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); // add shadow for 3D feel
  border-radius: 5px;
}
.map-control:hover {
  color: black;
}
.divider {
  border-top: 1px solid #82d616;
  width: 80%;
  margin: 10px auto;
}

text {
  font-size: 15px;
  text-anchor: middle;
  dominant-baseline: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  max-width: 100px;
}

#product-name {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 25px;
  background-color: white;
  color: grey;
  padding: 10px;
  border: 3px solid grey;
  min-width: 130px;
}

.dropdown-nodes {
  display: none;
  position: absolute;
  background-color: #fff;

  box-shadow: 0rem 0.5rem 1.625rem -0.25rem rgba(20, 20, 20, 0.15),
    0rem 0.5rem 0.5625rem -0.3125rem rgba(20, 20, 20, 0.06);
  color: #67748e;
  width: 220px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  list-style-type: none;
  list-style: none;
  padding: 8px;
  border-radius: 8px;
}

.dropdown-nodes li {
  padding: 8px 8px;
  font-size: 12px;
}

.dropdown-nodes li:hover {
  background-color: #e9ecef;
  color: #344767;
}

.dropdown-nodes li:hover {
  border-radius: 4px;
}

// .dropdown-nodes li:hover:first-child:not(:last-child) {
//   border-radius: 8px 8px 0 0;
// }

// .dropdown-nodes li:hover:last-child:not(:first-child) {
//   border-radius: 0 0 8px 8px;
// }

.dropdown-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  display: none;
}

.dropdown-collapse {
  display: block;
}

.drawer-update-information {
  z-index: 1100;
}

.action-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
}

.action-item:hover {
  background-color: #333;
}

.action-item-right-text {
  font-size: 10px;
  color: #344767;
}
